body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pageContainer {
  margin: 5% 10%;
}

.questionBox {
  background-color: #eee;
  padding: 20px 40px;
}

.answerContainer {
  margin-top: 40px;
  background-color: aquamarine;
  padding: 20px 40px;
}

.page-container {
  display: flex;
  flex-direction: row;
}

.nav-container {
  flex:2;
  background-color: #eee;
}

.nav-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 50px;
}

.nav-container li a {
  display: block;
  padding: 15px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  margin:20px 40px;
  transition: 0.5s;
}

.nav-container li a.hover {
  background-color: rgb(255, 121, 121);
}

.nav-container li a:hover {
  background-color: rgb(255, 121, 121);
  transition: 0.5s;
}


.content-container {
   flex:8
}